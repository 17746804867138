// 严凯 V2.2.0
<template>
  <section style="margin: 0 24px">
    <!-- <template v-if="!isShowAdd"> -->
    <section class="form-style">
      <a-form layout="inline">
        <a-form-item label="" @click="clickFocus">
          <a-input ref="inputbox" class="form-input" allowClear :maxlength="20" v-model:value="searchVal"
            placeholder="请输入角色名称">
            <template #prefix>
              <img src="@/assets/svg/search.svg" @click="searchList" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-btns">
          <a-button @click="searchList" class="form-btn">查询</a-button>
          <!-- <a-button class="form-btn" @click="openModal('add')" v-btn="'add'">添加角色</a-button> -->
        </a-form-item>
      </a-form>
    </section>
    <section class="table-style">
      <div class="table-head">
        <span class="title">角色列表</span>
        <a-button class="add-btn" @click="$router.push('/businessManage/role/add')" v-btn="'add'">
          <PlusOutlined class="add-icon" />添加角色
        </a-button>
      </div>
      <a-table :columns="roleColumns" :dataSource="dataSource" :pagination="pagination" @change="handleTanChange"
        :loading="tableLoading" rowKey="id" ref="tableRef" :scroll="{ x: true }">
        <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
        <template #name="{ text }">
          <span v-if="text?.length < 15">{{ text ? text : '-' }}
            <span v-if="text == '系统-印章管理员' || text == '系统-档案管理员'" class="default-role">
              系统
            </span>
          </span>
          <div v-else class="text-ellipsis-15">
            <span :title="text">{{ text ? text : '-' }}</span>
          </div>
        </template>
        <template #roleExplain="{ text }">
          <span v-if="text?.length < 15">{{ text ? text : '-' }}</span>
          <div v-else class="text-ellipsis-15">
            <span :title="text">{{ text ? text : '-' }}</span>
          </div>
        </template>
        <!-- 授权人员 -->
        <template #staffNames="{ text }">
          <span v-if="text?.length < 10">{{ text ?? '-' }}</span>
          <div v-else class="text-ellipsis-10">
            <span :title="text">{{ text ?? '-' }}</span>
          </div>
        </template>
        <template #action="{ record }">
          <!-- <a @click="openModal('edit', record)" style="margin-right: 16px" v-btn="'edit'" class="table-text-color" -->
          <a @click="$router.push('/businessManage/role/edit?id=' + record.id)" style="margin-right: 16px"
            v-btn="'edit'" class="table-text-color">授权</a>
          <a @click="openModal('delete', record)" v-if="!record.code.startsWith('DEFAULT_')" v-btn="'delete'"
            class="table-text-color">删除</a>
        </template>
      </a-table>
    </section>
    <!-- </template> -->
    <modal v-model:visible="visible" v-model:loading="loading" :permissionList="permissionList" :current="current"
      @modelSubmit="addRole" :type="actionType" :organizationalList="organizationalList" />
  </section>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, ref, createVNode } from 'vue'
import { roleColumns } from '../columns'
import modal from './components/modal'
import { paginationParams } from '@/utils/constData'
import { PlusOutlined, ExclamationOutlined } from '@ant-design/icons-vue'
import {
  getRoleList,
  addNewRole,
  editRole,
  deleteRole,
  getPermissionList,
  getSelfPermission,
  getDepartmentShow
} from '@/apis/businessManage'
import { Modal } from 'ant-design-vue'
import { cmsNotice, setTablePageFooterStyle } from '@/utils/utils'

export default defineComponent({
  components: {
    modal,
    PlusOutlined
  },
  setup() {
    const inputbox = ref(null)
    const tableRef = ref()
    const state = reactive({
      searchVal: undefined,
      pagination: {
        ...paginationParams
      },
      roleColumns,
      dataSource: [],
      visible: false,
      current: undefined,
      actionType: 'add',
      permissionList: [],
      loading: false,
      tableLoading: true,
      organizationalList: [],
      currentNum: 1
    })

    const getList = async () => {
      state.tableLoading = true
      const params = {
        isSystem: true,
        searchName: state.searchVal,
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize
      }
      const res = await getRoleList(params)
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.dataSource = res.data.map(item => (item.key = item.id + '' && item))
      state.dataSource = state.dataSource.map(i => {
        return {
          ...i,
          roleType: i.code.includes('DEFAULT') ? '系统角色' : '自定义角色'
        }
      })
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
    }
    const fetchPermissionList = async () => {
      const selectedObj = {
        用印管理: true,
        用印申请: true,
        用印记录: true,
        审批中心: true,
        待审批: true,
        已审批: true
      }
      function replacePermissionFeild(arr) {
        return arr.map(item => {
          const newItem = { ...item, disabled: selectedObj[item.name] ?? false }
          if (newItem.child && newItem.child.length) {
            newItem.child = replacePermissionFeild(newItem.child)
          }
          return newItem
        })
      }
      const res = await getPermissionList()
      state.permissionList = replacePermissionFeild(res.data)
      // console.log(state.permissionList)
    }
    const handleTanChange = ({ current, pageSize }) => {
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      state.dataSource = []
      getList()
    }
    const openModal = async (type, current) => {
      if (type === 'delete') {
        Modal.confirm({
          title: '确认删除！',
          content: '删除后无法恢复，是否删除？',
          centered: true,
          confirmLoading: state.loading,
          icon: createVNode(ExclamationOutlined),
          onOk: async () => {
            const res = await deleteRole({ id: current.id })
            if (res.success) {
              cmsNotice('success', '删除成功')
              state.pagination.total %
                (state.pagination.current * state.pagination.pageSize - state.pagination.pageSize) ===
                1 && (state.pagination.current -= 1)
              getList()
            }
          }
        })
      } else if (type === 'add') {
        state.actionType = type
        state.visible = true
      } else if (type === 'edit') {
        state.visible = true
        const res = await getSelfPermission({ id: current.id })
        state.current = res.data
        state.actionType = type
      }
    }
    const addRole = async values => {
      if (state.actionType === 'add') {
        const res = await addNewRole({ roleExplain: values.mark, name: values.roleName, permissionsIds: values.power })
        if (res.success) {
          cmsNotice('success', '添加成功')
          getList()
        }
        state.isShowAdd = false
      } else if (state.actionType === 'edit') {
        const res = await editRole({
          roleExplain: values.mark,
          name: values.roleName,
          permissionsIds: values.power,
          id: state.current.userRole.id,
          deptIds: values.deptIds,
          dataScope: values.dataScope
        })
        if (res.success) {
          cmsNotice('success', '修改成功')
          getList()
        }
      }
      state.loading = false
      state.visible = false
    }
    const searchList = () => {
      // 清空前后空格
      if (state.searchVal) {
        state.searchVal = state.searchVal.replace(/\s*/g, '')
      }
      state.pagination.current = state.searchVal ? 1 : state.currentNum
      getList()
    }

    const getOrganizationalList = async () => {
      // 获取流程范围中部门列表的函数
      const res = await getDepartmentShow()
      state.organizationalList = res.data.length ? res.data : []
    }
    // 搜索框点击
    const clickFocus = () => {
      inputbox.value.focus()
    }

    onMounted(() => {
      getList()
      fetchPermissionList()
      getOrganizationalList()
    })

    return {
      ...toRefs(state),
      searchList,
      openModal,
      addRole,
      handleTanChange,
      tableRef,
      clickFocus,
      inputbox
    }
  }
})
</script>

<style lang="scss" scoped>
.text-ellipsis-10 {
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis-15 {
  max-width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.default-role {
  width: 32px;
  height: 20px;
  background: linear-gradient(360deg, #D0D2D9 0%, #F0F1F5 100%);
  border-radius: 3px;
  border-image: linear-gradient(358deg, #e7e9ec, #cbced5) 1 1;
  font-size: 12px;
  line-height: 20px;
  color: #30343e88;
  display: inline-block;
  text-align: center;
}
</style>
